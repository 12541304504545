// middleware/redirect.global.js
export default defineNuxtRouteMiddleware((to, from) => {
  // Si la ruta contiene "marcos-cycles", redirige permanentemente (301)
  if (to.path.includes('marcos-cycles')) {
    return navigateTo('https://www.marcosautomocion.es', { redirectCode: 301, external:true });
  }

  // Si la ruta no contiene un punto y no termina con una barra, añade la barra
  if (!to.path.includes('.') && !to.path.endsWith('/')) {
    const newPath = to.path + '/';
    return navigateTo(newPath);
  }
});
