// [FILE]: repository/modules/news.ts
// 3rd's
import type { FetchOptions } from 'ofetch';
import type { AsyncDataOptions } from '#app';
// locals
import FetchFactory from '../factory';
import type { Donation } from '../types';


class DonacionesModule extends FetchFactory<T> {
  private RESOURCE = '/donaciones';

  /**
   * Devuelve las donaciones
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async show (
    page: string,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}/${page}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * Confirmación de la donación
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async confirmation (
    payload: Donation,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {
    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'GET',
          `${this.RESOURCE}/${payload.campaign}/confirmation/${payload.token}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  /**
   * Envia los datos del formulario de una donación
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async store (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions: FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          }
        };
        return this.call(
          'POST',
          `${this.RESOURCE}`,
          formData, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

  

}

export default DonacionesModule;